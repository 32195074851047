const routes = [
  {
    path: "/relatorio-de-alunos-concludentes",
    name: "relatorio_de_alunos_concludentes",
    meta: {
      allowedPermissions: ["alunos_concludentes_pdf"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/RelatorioDeAlunosConcludentes/RelatorioDeAlunosConcludentes.vue"
      ),
  },
];

export default routes;
