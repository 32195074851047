const routes = [
  {
    path: "/permitir-vincular-historico-para-cumprir-dependencia-de-fase-ensino-disciplina",
    name: "permitir_vincular_historico_para_cumprir_dependencia_de_fase_ensino_disciplina",
    meta: {
      allowedPermissions: ["vincular_historico"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/PermitirVincularHistoricoParaCumprirDependenciaDeFaseEnsinoDisciplina/PermitirVincularHistoricoParaCumprirDependenciaDeFaseEnsinoDisciplinaHome.vue"
      ),
  },
  {
    path: "/permitir-vincular-historico-para-cumprir-dependencia-de-fase-ensino-disciplina/:id",
    name: "permitir_vincular_historico_para_cumprir_dependencia_de_fase_ensino_disciplina_editar",
    meta: {
      allowedPermissions: ["vincular_historico"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/PermitirVincularHistoricoParaCumprirDependenciaDeFaseEnsinoDisciplina/PermitirVincularHistoricoParaCumprirDependenciaDeFaseEnsinoDisciplinaEdit.vue"
      ),
  },
];
export default routes;
