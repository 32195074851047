const routes = [
  {
    path: "/permitir-inserir-historico-do-aluno",
    name: "permitir_inserir_historico_do_aluno",
    meta: {
      allowedPermissions: ["vincular_historico"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/PermitirInserirHistoricoDoAluno/PermitirInseririHistoricoDoAlunoHome.vue"
      ),
  },
  {
    path: "/inserir-historico-escolar-do-aluno-cadastrar/novo/:aluno_id",
    name: "inserir_historico_escolar_do_aluno_cadastrar",
    meta: {
      allowedPermissions: ["vincular_historico"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/PermitirInserirHistoricoDoAluno/PermitirInserirHistoricoDoALunoCad.vue"
      ),
  },
  {
    path: "/inserir-historico-escolar-do-aluno/editar/:id",
    name: "inserir_historico_escolar_do_aluno_editar",
    meta: {
      allowedPermissions: ["vincular_historico"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/PermitirInserirHistoricoDoAluno/PermitirInserirHistoricoDoAlunoEdit.vue"
      ),
  },
  {
    path: "/inserir-historico-escolar-do-aluno/visualizar/:id",
    name: "inserir_historico_escolar_do_aluno_visualizar",
    meta: {
      allowedPermissions: ["vincular_historico"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/PermitirInserirHistoricoDoAluno/PermitirInserirHistoricoDoAlunoVisualizar.vue"
      ),
  },
];
export default routes;
