const routes = [
  {
    path: "/relatorio-de-alunos-com-pendencias-no-historico",
    name: "relatorio_de_alunos_com_pendencias_no_historico",
    meta: {
      allowedPermissions: ["alunos_pendencia_historico_pdf"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/RelatorioDeAlunosComPendenciasNoHistorico/RelatorioDeAlunosComPendenciasNoHistorico.vue"
      ),
  },
];

export default routes;
