const routes = [
  {
    path: "/emitir-historico-escolar",
    name: "emitir_historico_escolar",
    meta: {
      allowedPermissions: ["emitir_historico_escolar"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import("@/views/EmitirHistoricoEscolar/EmitirHistoricoEscolar.vue"),
  },
];

export default routes;
