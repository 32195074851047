import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$attrs.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass({ required: _ctx.$attrs.required })
        }, _toDisplayString(_ctx.$attrs.label), 3 /* TEXT, CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("textarea", _mergeProps({
      class: "form-control",
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onInput && $setup.onInput(...args)))
    }, _ctx.$attrs, { rows: "2" }), null, 16 /* FULL_PROPS */, _hoisted_1)
  ]))
}