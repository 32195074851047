<template>
  <hr class="mt-5" v-if="!porpHideHr" />
  <div class="row gy-5">
    <div class="col-sm-6" v-if="!porpHideDataInclusao">
      <InputCustom
        label="Data e Hora de Inclusão:"
        :disabled="true"
        :model-value="dataInclusao"
      />
    </div>
    <div class="col-sm-6" v-if="!porpHideUsuarioInclusao">
      <InputCustom
        label="Usuário de Inclusão"
        :disabled="true"
        :model-value="propPayload.usuario_inclusao"
        placeholder=""
      />
    </div>
    <div class="col-sm-6" v-if="!porpHideDataAlteracao">
      <InputCustom
        label="Data e Hora de Alteração"
        :disabled="true"
        v-model="dataAlteracao"
      />
    </div>
    <div class="col-sm-6" v-if="!porpHideUsuarioAlteracao">
      <InputCustom
        label="Usuário de Alteração"
        :disabled="true"
        :model-value="propPayload.usuario_alteracao"
        placeholder=""
      />
    </div>
  </div>
</template>
<script lang="ts">
import { convertDateBrComHora } from "@/core/helpers/convertDate";
import {
  defineComponent,
  PropType,
  onMounted,
  reactive,
  watch,
  toRefs,
} from "vue";
export default defineComponent({
  name: "Grade-Edicao",
  data() {
    return {
      dataInclusao: "",
      dataAlteracao: "",
    };
  },
  props: {
    propPayload: {
      type: Object as PropType<any>,
      required: true,
      default: () => ({}),
    },
    porpHideHr: {
      type: Boolean,
      required: false,
      default: false,
    },
    porpHideDataInclusao: {
      type: Boolean,
      required: false,
      default: false,
    },
    porpHideUsuarioInclusao: {
      type: Boolean,
      required: false,
      default: false,
    },
    porpHideUsuarioAlteracao: {
      type: Boolean,
      required: false,
      default: false,
    },
    porpHideDataAlteracao: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      dataInclusao: "",
      dataAlteracao: "",
    });
    onMounted(() => {
      data.dataInclusao = props.propPayload.data_inclusao
        ? convertDateBrComHora(props.propPayload.data_inclusao)
        : "";
      data.dataAlteracao = props.propPayload.data_alteracao
        ? convertDateBrComHora(props.propPayload.data_alteracao)
        : "";
    });

    watch(
      () => props.propPayload,
      (payload: any) => {
        data.dataInclusao = payload.data_inclusao
          ? convertDateBrComHora(payload.data_inclusao)
          : "";
        data.dataAlteracao = payload.data_alteracao
          ? convertDateBrComHora(payload.data_alteracao)
          : "";
      }
    );

    return {
      ...toRefs(data),
    };
  },
});
</script>
