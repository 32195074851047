import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { exibeOverlay, removeOverlay } from "@/core/helpers/dom";
import KeycloakService from "./KeycloakService";

/**
 * @description service to call HTTP request via Axios
 */
class ApiServiceCustom {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  public static coutRequest: number;

  public constructor(
    public defaultUrl: string,
    public resource: string,
    public slug?: string,
    public params?: object
  ) {}

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiServiceCustom.vueInstance = app;
    ApiServiceCustom.vueInstance.use(VueAxios, axios);
    ApiServiceCustom.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_HISTORICO_ESCOLAR_API;
    ApiServiceCustom.initLoader();
    ApiServiceCustom.setHeader();
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    const token = process.env.VUE_APP_JWT_TOKEN;
    const keycloakToken = KeycloakService.getInstance().token;
    ApiServiceCustom.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;

    ApiServiceCustom.vueInstance.axios.defaults.headers.common[
      "X-Apikey"
    ] = `Bearer ${token}`;

    ApiServiceCustom.vueInstance.axios.defaults.headers.common[
      "x-autenticacao"
    ] = `Bearer ${keycloakToken}`;

    ApiServiceCustom.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @returns Promise<AxiosResponse>
   * @param resource
   * @param params
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static get({
    resource = "" as string,
    slug = "" as string,
    defaultUrl = "" as string,
    params = {} as object,
  }): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.get(
      `${defaultUrl}/${resource}${slug}`,
      { params }
    );
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public get({
    resource = this.resource as string,
    slug = "" as string,
    defaultUrl = this.defaultUrl as string,
    params = {} as object,
    responseType = "json" as
      | "arraybuffer"
      | "blob"
      | "document"
      | "json"
      | "text"
      | "stream",
  }): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.get(
      `${defaultUrl}/${resource}${slug}`,
      {
        params,
        responseType,
      }
    );
  }

  public getDataPagination(
    currentPage = 1,
    search = {},
    sortBy = "",
    resource = this.resource as string,
    defaultUrl = this.defaultUrl as string
  ): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.get(`${defaultUrl}/${resource}`, {
      params: {
        page: currentPage,
        ...search,
        sortBy: !sortBy ? undefined : sortBy,
      },
    });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static post({
    resource = "" as string,
    params = "" as string,
    defaultUrl = "" as string,
  }): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.post(
      `${defaultUrl}/${resource}`,
      params
    );
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public post({
    resource = this.resource as string,
    params = "" as string,
    defaultUrl = this.defaultUrl as string,
  }): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.post(
      `${defaultUrl}/${resource}`,
      params
    );
  }

  /**
   * @description send the UPDATE HTTP request
   * @returns Promise<AxiosResponse>
   * @param resource
   * @param slug
   * @param dados
   */
  public static update(
    resource: string,
    slug: string,
    dados: string
  ): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.put(`${resource}/${slug}`, dados);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static put({
    resource = "" as string,
    params = "" as string,
    slug = "" as string,
    defaultUrl = "" as string,
  }): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.put(
      `${defaultUrl}/${resource}/${slug}`,
      params
    );
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public put({
    resource = this.resource as string,
    params = "" as string,
    slug = "" as string,
    defaultUrl = this.defaultUrl as string,
  }): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.put(
      `${defaultUrl}/${resource}/${slug}`,
      params
    );
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public patch({
    resource = this.resource as string,
    params = "" as string,
    slug = "" as string,
    defaultUrl = this.defaultUrl as string,
  }): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.patch(
      `${defaultUrl}/${resource}/${slug}`,
      params
    );
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete({
    resource = "" as string,
    slug = "" as string,
    defaultUrl = "" as string,
  }): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.delete(
      `${defaultUrl}/${resource}/${slug}`
    );
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public delete({
    resource = this.resource as string,
    slug = "" as string,
    defaultUrl = this.defaultUrl as string,
  }): Promise<AxiosResponse> {
    return ApiServiceCustom.vueInstance.axios.delete(
      `${defaultUrl}/${resource}/${slug}`
    );
  }

  public static initLoader() {
    ApiServiceCustom.coutRequest = 0;
    ApiServiceCustom.vueInstance.axios.interceptors.request.use(function (
      request: AxiosRequestConfig
    ) {
      ApiServiceCustom.coutRequest++;
      exibeOverlay();

      return request;
    });

    ApiServiceCustom.vueInstance.axios.interceptors.response.use(
      function (response: AxiosResponse) {
        ApiServiceCustom.coutRequest--;
        if (ApiServiceCustom.coutRequest === 0) {
          removeOverlay();
        }

        return response;
      },
      function (error) {
        ApiServiceCustom.coutRequest--;
        if (ApiServiceCustom.coutRequest === 0) {
          removeOverlay();
        }
        return Promise.reject(error);
      }
    );
  }
}

export default ApiServiceCustom;
