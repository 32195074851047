const routes = [
  {
    path: "/relatorios-de-alunos-em-progressao-parcial",
    name: "relatorios_de_aluno_em_progressao_parcial",
    meta: {
      allowedPermissions: ["aluno_progressao_parcial_pdf"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/RelatoriosDeAlunosEmProgressaoParcial/RelatoriosDeAlunosEmProgressaoParcial.vue"
      ),
  },
];

export default routes;
