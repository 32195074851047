<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import setup from "../setupComposable/setup";
import commonProps from "../props/commonProps";

defineProps(commonProps);
const emit = defineEmits(["update:modelValue"]);
const { onInput } = setup(emit);
</script>

<template>
  <div>
    <label v-if="$attrs.label" :class="{ required: $attrs.required }">
      {{ $attrs.label }}
    </label>
    <textarea
      class="form-control"
      :value="modelValue"
      @input="onInput"
      v-bind="$attrs"
      rows="2"
    ></textarea>
  </div>
</template>
