import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import KeycloakService from "@/core/services/KeycloakService";
import MensagemService from "@/core/services/MensagemService";

const childrenRoutes: RouteRecordRaw[] = [];
const paths = require.context("../router", true, /routes.ts$/i);
const context = paths
  .keys()
  .map(paths)
  .map((m: any) => m.default);

context.map((arr) => {
  if (arr) {
    arr.map((val: RouteRecordRaw) => {
      childrenRoutes.push(val);
    });
  }
});

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: childrenRoutes,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function validarAutorizacao(to, permissions) {
  if (to.meta.requireAuth === false) {
    return true;
  }

  if (!KeycloakService.getInstance().authenticated) {
    window.location.href = process.env?.VUE_APP_REDIRECT_LOGIN || "";
  }

  if (!to.meta.allowedPermissions) {
    return false;
  }

  if (!Array.isArray(to.meta.allowedPermissions)) {
    return true;
  }

  if (to.meta.allowedPermissions.some((item) => permissions.includes(item))) {
    return true;
  }

  return false;
}

router.beforeEach((to, from, next) => {
  //reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const storedMenuData: string = localStorage.getItem("menuData") || "";
  const permissions = Object.values(JSON.parse(storedMenuData)).flat();

  if (validarAutorizacao(to, permissions)) {
    next();
  } else {
    MensagemService.erro({
      texto: "Você não tem permissão para acessar este recurso!",
      confirmButtonText: "OK",
    });
  }

  //Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
