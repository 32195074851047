<template>
  <div class="card" style="width: 28rem">
    <div class="card-header">
      <div
        class="d-flex justify-content-between align-items-center tittle-card"
      >
        <h3 class="card-title">{{ title }}</h3>
        <a @click="closeDropDownAndCancel" class="btn btn-icon btn-danger"
          ><i class="fa fa-times fs-4"></i
        ></a>
      </div>
    </div>
    <div class="card-body">
      <div
        v-for="(field, index) in fields"
        :key="index"
        class="row mb-5"
        v-show="showFieldByIndex(index)"
      >
        <div class="col-sm-12">
          <label v-if="field.label">{{ field.label }}:</label>
          <InputCustom
            required
            type="text"
            :placeholder="field.label"
            :name="field.name"
            v-model="result[index].value"
            v-if="field.type == 'text'"
          />
          <InputCustom
            :placeholder="field.label"
            :name="field.name"
            :pattern="validacoes.INTEIRO_MAIOR_1900"
            v-mask="'0000'"
            v-model="result[index].value"
            v-if="field.type == 'textDate'"
          />
          <MunicipioUf
            :required="field.required"
            :currentUf="uf"
            :is-filter="true"
            :placeholder="field.label"
            :name="field.name"
            v-model="result[index].value"
            ufClass="col-sm-12"
            municipioClass="col-sm-12 mt-5"
            v-if="field.type == 'municipio'"
          />
          <select
            :name="field.name"
            class="form-select"
            v-if="field.type == 'select'"
            v-model="result[index].value"
            @change="onChangeSection($event, field.name)"
          >
            <option disabled selected value="">Selecione uma das opções</option>
            <option
              v-for="option in field.options"
              :value="option.value"
              :key="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <Multiselect
            class="select-search"
            v-if="field.type == 'select-search'"
            v-model="result[index].value"
            placeholder="Selecione um das opçoes"
            :options="field.options"
            :searchable="true"
            @change="onChangeSectionMultiselect($event, field.name)"
          />
          <Multiselect
            class="select-search"
            v-if="field.type == 'select-search-on-sync'"
            v-model="result[index].value"
            :placeholder="
              field.placeholder ? field.placeholder : 'Digite para pesquisar'
            "
            :resolve-on-load="false"
            :delay="0"
            :options="field.options"
            :searchable="true"
            @change="onChangeSectionMultiselect($event, field.name)"
          />
        </div>
      </div>
      <div class="col-sm-12 mb-5 pt-2" v-if="fields.length > 4">
        <span class="btn btn-light-primary w-100" @click="showMoreOptions()">
          <span v-if="data.more_options">
            Mais Opções <i class="bi bi-chevron-up"></i
          ></span>
          <span v-else> Menos Opções <i class="bi bi-chevron-down"></i> </span>
        </span>
      </div>
      <div class="d-flex d-flex flex-row-reverse pt-5">
        <button
          @click="onInput"
          type="submit"
          class="btn btn-primary"
          style="margin-left: 15px"
        >
          Aplicar
        </button>
        <button type="button" @click="clearAndClose" class="btn btn-danger">
          Limpar
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, PropType } from "vue";
import Multiselect from "@vueform/multiselect";
import { validations } from "@/core/helpers/formatosInputs";

export default defineComponent({
  name: "custom-search",
  components: {
    Multiselect,
  },
  props: {
    fields: {
      type: Array as PropType<any[]>,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    let data = reactive({
      researched: false,
      more_options: true,
    });
    const validacoes = computed(() => {
      return validations;
    });

    return {
      data,
      validacoes,
    };
  },
  data: () => {
    return {
      result: [] as any,
      uf: "" as any,
    };
  },
  methods: {
    onInput() {
      this.data.researched = true;
      let values = JSON.parse(JSON.stringify(this.result));

      for (let i = 0; i < values.length; i++) {
        if (!values[i].value) {
          values[i].value = "";
        }
      }

      this.$emit("aplicar", values);
      this.closeDropDown();
    },
    closeDropDown() {
      this.$emit("close", "");
    },
    clearAndClose() {
      this.cleanAllInput();
      this.$emit("cleaned", { cleaned: true });
      /*if (this.data.researched) {
        this.data.researched = false;
        this.$emit("canceled", { canceled: true });
      } */
    },
    closeDropDownAndCancel() {
      this.closeDropDown();
      if (this.data.researched) {
        this.data.researched = false;
        this.$emit("canceled", { canceled: true });
      }
    },
    onChangeSection(event, name) {
      this.$emit("selected", { name, value: event.target.value });
    },
    onChangeSectionMultiselect(value, name) {
      this.$emit("selected", { name, value });
    },
    getData() {
      this.fields.forEach((item) => {
        let value = "";
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if ("value" in item) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value = item.value ? item.value : "";
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.result.push({ name: item.name, value: value });
      });
    },
    cleanAllInput() {
      this.uf = "clearfilter";
      setTimeout(() => {
        this.uf = process.env.VUE_APP_UF_PADRAO;
      }, 1);
      for (let i = 0; i < this.result.length; i++) {
        this.result[i].value = "";
      }
    },
    showField(field) {
      //v-show="showField(field) && showFieldByIndex(index)"
      if ("show" in field) {
        if (field.show === false) {
          return false;
        }
      }

      return true;
    },
    showMoreOptions() {
      this.data.more_options = !this.data.more_options;
    },
    showFieldByIndex(index) {
      if (index < 4) return true;
      if (!this.data.more_options) return true;
      return false;
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    this.fields.forEach((option, index) => {
      if (this.fields[index]?.currentUf) {
        this.uf = this.fields[index]?.currentUf;
      }
    });
  },
});
</script>

<style>
.s-obrigatorio,
small {
  color: red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.tittle-card {
  width: 100%;
}
.tittle-card a {
  width: 25px !important;
  height: 25px !important;
}
.select-search {
  border: 1px solid #b5b5c3;
  border-radius: 0.475rem;
}
</style>
