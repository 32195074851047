const routes = [
  {
    path: "/manutencao-de-amparo-legal-para-historico-escolar",
    name: "manutencao_de_amparo_legal_para_historico_escolar",
    meta: {
      allowedPermissions: ["amparo_list"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/ManutencaoDeAmparoLegalParaHistoricoEscolar/ManutencaoDeAmparoLegalParaHistoricoEscolarHome.vue"
      ),
  },
  {
    path: "/manutencao-de-amparo-legal-para-historico-escolar/novo",
    name: "manutencao_de_amparo_legal_para_historico_escolar_novo",
    meta: {
      allowedPermissions: ["amparo_create"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/ManutencaoDeAmparoLegalParaHistoricoEscolar/ManutencaoDeAmparoLegalParaHistoricoEscolarCad.vue"
      ),
  },
  {
    path: "/manutencao-de-amparo-legal-para-historico-escolar/:id/:option",
    name: "manutencao_de_amparo_legal_para_historico_escolar_editar",
    meta: {
      allowedPermissions: ["amparo_edit"],
      breadCrumb: "Histórico Escolar",
    },
    component: () =>
      import(
        "@/views/ManutencaoDeAmparoLegalParaHistoricoEscolar/ManutencaoDeAmparoLegalParaHistoricoEscolarEdit.vue"
      ),
  },
];

export default routes;
