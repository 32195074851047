<template>
  <div class="table-responsive">
    <div v-if="loading" class="d-flex justify-content-center">
      <div class="spinner-grow text-primary" role="status"></div>
    </div>
    <table
      v-if="!loading && listas.length"
      class="table table-bordered table-striped table-hover"
    >
      <thead class="fw-bold">
        <tr>
          <th
            v-for="header in cabecalhoTabela"
            :key="header.topico"
            scope="col"
            class="min-w-150px p-3 px-5 fw-bold"
          >
            {{ header.topico }}
          </th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr v-for="lista in listas" :key="lista.id">
          <td
            v-for="dado in dadosTabela"
            :key="dado.campo"
            class="p-3 px-5 td-middle fw-normal"
          >
            {{ lista[dado.campo] }}
            <slot :listaSlot="lista" :colunaSlot="dado.campo"></slot>
          </td>

          <td class="p-3 td-right" v-show="!hideAction">
            <BotaoAcoes
              :acoes="acoes"
              :id="lista.id"
              v-if="acoes.length && !apenasVisualizar"
            />
            <BotaoComponent
              text="Visualizar"
              @click="goToRoute(lista.id)"
              v-else-if="apenasVisualizar"
            />
            <BotaoEditar
              :rotaEdit="rotaEdit"
              :rotaId="lista.id"
              :iconClass="iconClass"
              :btnText="btnText"
              v-else
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <h3 class="text-center">{{ mensagemNenhumRegistro }}</h3>
    </div>
  </div>
  <div v-show="bottomLabel" class="bottom-label">Arraste para o lado >></div>
</template>
<script lang="ts">
import { defineComponent, reactive, PropType } from "vue";
import BotaoEditar from "../Botoes/BotaoEditar.vue";
import BotaoAcoes from "../Botoes/BotaoAcoes.vue";
import ActionInterface from "./Interface/ActionInterface";
import BotaoComponent from "../Botoes/BotaoComponent.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "tabela-listagem",
  components: {
    BotaoEditar,
    BotaoAcoes,
    BotaoComponent,
  },
  props: {
    mensagemNenhumRegistro: {
      type: String,
      default: "Nenhum Registro Encontrado",
    },
    listas: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    cabecalhoTabela: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    dadosTabela: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      required: false,
    },
    rotaEdit: {
      type: String,
      required: false,
      default: "",
    },
    apenasVisualizar: {
      type: Boolean,
      required: false,
      default: false,
    },
    rotaVisualizar: {
      type: String,
      required: false,
      default: "",
    },
    acoes: {
      type: Array as PropType<ActionInterface[]>,
      required: false,
      default: () => [],
    },
    baseId: {
      type: String as PropType<any>,
      required: false,
      default: "",
    },
    hideAction: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconClass: {
      type: String,
      required: false,
      default: "bi-pencil-fill",
    },
    btnText: {
      type: String,
      required: false,
      default: "",
    },
    bottomLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const rota = reactive({
      rotaId: "",
    });

    const router = useRouter();

    function goToRoute(id) {
      return router.push({
        name: props.rotaVisualizar,
        params: { baseId: props.baseId, destinoId: id },
      });
    }

    return { rota: rota.rotaId, goToRoute };
  },
});
</script>

<style scoped>
.bottom-label {
  display: flex;
  justify-content: end;
  color: #5d5ffe;
  width: 100%;
}
</style>
